import { Component, OnInit, PipeTransform } from '@angular/core';
import { Directive, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import { IMyDpOptions } from 'mydatepicker';
import { IMyDrpOptions, IMyDateRangeModel } from 'mydaterangepicker';


@Component({
  selector: 'app-expense',
  templateUrl: './expense.component.html',
})
export class ExpenseComponent implements OnInit {

  expenseList = [];
  private dateRange: any;
  beginDateTime = null;
  endDateTime = null;
  totalAmount = null;

  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'mm/dd/yyyy'
  };

  public myDateRangePickerOptions: IMyDrpOptions = {
    // other options...
    editableDateRangeField: false,
    dateFormat: 'mm/dd/yyyy',
    showClearBtn: false,
    showClearDateRangeBtn: false
  };

  // Constructor
  constructor(private dataService: DataService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal) {
  }


  //OnInit is called after constructor
  ngOnInit() {
    this.setRange();
    this.getExpense();
  }

  setRange() {
    let date = new Date();
    var nowDate = new Date();
    nowDate.setDate(nowDate.getDate() - 30);
    this.dateRange = {
      beginDate: { year: nowDate.getFullYear(), month: nowDate.getMonth() + 1, day: nowDate.getDate() },
      endDate: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
    };
  }

  AddEditContributors(addEditMode: any) {
    const modalRef = this.modalService.open(ExpenseAddEditComponent, { size: 'xl' });
    modalRef.componentInstance.addEditMode = addEditMode;
    modalRef.result.then((data) => {
      // on close
      this.getExpense();
    }, (reason) => {
      // on dismiss
    });
  }

  EditContributor(selectedContributor: any) {
    const modalRef = this.modalService.open(ExpenseAddEditComponent, { size: 'xl' });
    modalRef.componentInstance.addEditMode = 'E';
    modalRef.componentInstance.existingContributor = selectedContributor;

  }

  getExpense() {
    this.spinner.show();
    this.dataService.getExpense({
      dateRange: this.dateRange,
      organizationId: 2
    })
      .subscribe((result) => {
        //console.log(result);
        this.expenseList = result.ledger;
        this.beginDateTime = result.beginDateTime;
        this.endDateTime = result.endDateTime;
        this.totalAmount = result.totalAmount;
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        console.error(error)
      });
  }

  onDateRangeChanged(event: IMyDateRangeModel) {
    if (event) {
      this.dateRange.beginDate = event.beginDate;
      this.dateRange.endDate = event.endDate;
      this.getExpense();
    }
  }

}


@Component({
  selector: 'app-expense-view',
  template: '<div class="container"><app-expense></app-expense></div>',
})
export class ExpenseViewComponent {

}


@Component({
  selector: 'app-expense-add-edit',
  templateUrl: './expense-add-edit.component.html'
})

export class ExpenseAddEditComponent implements OnInit {
  // Declarations
  @Input() addEditMode: any;
  @Input() existingContributor: any;

  modalHeader: string;
  dataForm: FormGroup;
  submitted = false;
  departmentsList = [];
  ministriesList = [];
  contributorStatusList = [];
  billPayTypesList = [];
  accountsList = [];
  contributionTypesList = [];

  constructor(public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    public config: NgbModalConfig,
    private dataService: DataService,
    private spinner: NgxSpinnerService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {
    this.setModalHeader();
    this.initializeForm();
    this.getBillPayTypes();
    this.getDepartments();
    this.getAccounts();

    if (this.addEditMode == 'E') {
      this.initializeInformation();
    }
  }

  setDate(): void {
    // Set today date using the patchValue function
    let date = new Date();
    this.dataForm.patchValue({
      dateEntered: {
        date: {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate()
        }
      }
    });
  }

  getBillPayTypes() {
    this.dataService.getBillPayTypes()
      .subscribe((result) => {
        console.log(result);
        this.billPayTypesList = result;
      }, error => console.error(error));
  }

  getAccounts() {
    this.dataService.getAccounts(2)
      .subscribe((result) => {
        if (result) {
          this.accountsList = result;
        }
      }, error => console.error(error));
  }

  getDepartments() {
    this.dataService.getDepartments()
      .subscribe((result) => {
        if (result) {
          this.departmentsList = result;
        }
      }, error => console.error(error));
  }

  setModalHeader() {
    if (this.addEditMode == 'A') {
      this.modalHeader = "Add Expense"
    }
    if (this.addEditMode == 'E') {
      this.modalHeader = "Edit Expense"
    }
  }

  // Initialize the form with formbuilder and initialize validation fields 
  initializeForm() {
    this.dataForm = this.formBuilder.group({
      dateEntered: [null, [Validators.required]],
      billPayTypeId: ['', [Validators.required]],
      accountId: ['', [Validators.required]],
      departmentId: ['', [Validators.required]],
      ministryId: [''],
      comments: [''],
      organizationId: [2],
      //id: [0],
      amount: [0.00, [Validators.required]],
      userIdEnteredBy: [0]
    });

    this.setDate();
    this.dataForm.get('userIdEnteredBy').setValue(this.dataService.getLoginUserId());
  }

  initializeInformation() {
    this.dataForm.get('firstName').setValue(this.existingContributor.firstName);
    this.dataForm.get('lastName').setValue(this.existingContributor.lastName);
    this.dataForm.get('middleName').setValue(this.existingContributor.middleName);
    this.dataForm.get('addressLine1').setValue(this.existingContributor.addressLine1);
    this.dataForm.get('addressLine2').setValue(this.existingContributor.addressLine2);
    this.dataForm.get('city').setValue(this.existingContributor.city);
    this.dataForm.get('zip').setValue(this.existingContributor.zip);
    this.dataForm.get('phone').setValue(this.existingContributor.phone);
    this.dataForm.get('state').setValue(this.existingContributor.state);
    this.dataForm.get('departmentId').setValue(this.existingContributor.departmentId);
    this.dataForm.get('ministryId').setValue(this.existingContributor.ministryId);
    this.dataForm.get('email').setValue(this.existingContributor.email);
    this.dataForm.get('comments').setValue(this.existingContributor.comments);
    this.dataForm.get('organizationId').setValue(this.existingContributor.organizationId);
    this.dataForm.get('status').setValue(this.existingContributor.status);
    this.dataForm.get('id').setValue(this.existingContributor.id);
  }


  // Convenience getter for easy access to form fields
  get f() { return this.dataForm.controls; }

  // Called on submit of the form
  onSubmit() {
    this.submitted = true;
    console.log(this.dataForm.value);
    console.log(this.dataForm);
    // stop here if form is invalid
    if (this.dataForm.invalid) {
      //alert('Form Invalid');
      return;
    }
    this.spinner.show();

    if (this.addEditMode == 'A') {
      // Add new contributor
      this.dataService.saveExpense(this.dataForm.value)
        .subscribe((data) => {
          this.spinner.hide();
          this.activeModal.close();
        }, (err) => console.error("Failed! " + err));

    }
    if (this.addEditMode == 'E') {
      this.dataService.editContributor(this.dataForm.value)
        .subscribe((data) => {
          console.log(data)
          this.spinner.hide();
        }, (err) => console.error("Failed! " + err));
    }


    // Edit Contributor
    this.spinner.hide();
  }

  //$event: This value refers to the payload of the selected dropdown element.
  onSelectDepartment(e: { target: { value: number; }; }) {
    this.getMinistriesByDepartment(e.target.value);
  }

  getMinistriesByDepartment(departmentId: number) {
    this.dataService.getMinistriesByDepartment(departmentId)
      .subscribe((result) => {
        if (result) {
          this.ministriesList = result;
        }
      }, error => console.error(error));
  }
}

