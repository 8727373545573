import { Component, OnInit, PipeTransform } from '@angular/core';
import { Directive, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { DataService } from '../data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import { IMyDrpOptions, IMyDateRangeModel } from 'mydaterangepicker';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
})
export class AccountsComponent implements OnInit {
  @Input() mode: string;

  accountsList = [];
  referralsCount = 0;
  filterReferral = "A";
  //referralsList: any[];
  filter = new FormControl('');

  // Constructor
  constructor(private dataService: DataService,
    private modalService: NgbModal) {
    //console.log(this.mode);
  }

  //OnInit is called after constructor
  ngOnInit() {
    this.getAccounts();
  }

  getAccounts() {
    this.dataService.getAccounts(2)
      .subscribe((result) => {
        // Check and verify if the details received are valid
        //console.dir(result[0]);
        if (result[0]) {
          //console.log(result);
          this.accountsList = result;
          // this.referralsList$ = result[0];
          // this.referralsCount = result[0].length;
        }
      }, error => console.error(error));

  }

  ViewActivity(account: any) {
    console.log(account);
    const modalRef = this.modalService.open(AccountActivityComponent, { size: 'xl' });
    modalRef.componentInstance.account= account;
    // modalRef.componentInstance.modalHeader =
    //   'Referral of ' + EmailObj.clientFirstName + ' ' + EmailObj.clientLastName
    //   + ' to ' + EmailObj.agencyName;
    // modalRef.componentInstance.emailObj = EmailObj;
    // modalRef.componentInstance.emailInfo = result[0];
    // modalRef.componentInstance.emailBody = result[0].sentEmailHtmlString;
  }

  AddEditAccount(addEditMode: any) {
    const modalRef = this.modalService.open(AccountAddEditComponent, { size: 'xl' });
    modalRef.componentInstance.addEditMode = addEditMode;
    modalRef.result.then((data) => {
      // on close
      this.getAccounts();
    }, (reason) => {
      // on dismiss
    });
  }

  EditData(selectedData: any) {
    const modalRef = this.modalService.open(AccountAddEditComponent, { size: 'xl' });
    modalRef.componentInstance.addEditMode = 'E';
    modalRef.componentInstance.existingData = selectedData;
    modalRef.result.then((data) => {
      // on close
      this.getAccounts();
    }, (reason) => {
      // on dismiss
    });
  }

}

@Component({
  selector: 'app-accounts-view',
  template: '<div class="container"><app-accounts></app-accounts></div>',
})
export class AccountsViewComponent {

}

@Component({
  selector: 'app-accounts-view-dashboard',
  template: '<div><app-accounts [mode]="\'D\'"></app-accounts></div>',
})
export class AccountsViewDashboardComponent {
}


@Component({
  selector: 'app-account-add-edit',
  templateUrl: './account-add-edit.component.html'
})

export class AccountAddEditComponent implements OnInit {

  // Declarations
  @Input() addEditMode: any;
  @Input() existingData: any;
  modalHeader: string;  
  dataForm: FormGroup;
  submitted = false;
  accountTypes: any[];
  selectedSubCategories = [];
  agencyClientDetails: any;
  //agencySeq= 292;
  clientSeq = 281308;
  transactionSeq = 1;

  routeParamAgency = null;

  constructor(public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    public config: NgbModalConfig,
    private dataService: DataService,
    private spinner: NgxSpinnerService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {
    this.setModalHeader();
    this.initializeForm();
    this.getAccountTypes();

    if (this.addEditMode === 'E') {
      this.initializeInformation();
    }
  }

  initializeInformation() {
    if (this.existingData.name !== null) {
      this.dataForm.get('name').setValue(this.existingData.name);
    }

    if (this.existingData.accountNumber !== null) {
      this.dataForm.get('accountNumber').setValue(this.existingData.accountNumber);
    }

    if (this.existingData.accountTypeId !== null) {
      this.dataForm.get('accountTypeId').setValue(this.existingData.accountTypeId);
    }

    if (this.existingData.beginningBalance !== null) {
      this.dataForm.get('beginningBalance').setValue(this.existingData.beginningBalance);
    }

    if (this.existingData.organizationId !== null) {
      this.dataForm.get('organizationId').setValue(this.existingData.organizationId);
    }

    if (this.existingData.comments !== null) {
      this.dataForm.get('comments').setValue(this.existingData.comments);
    }

    if (this.existingData.id !== null) {
      this.dataForm.get('id').setValue(this.existingData.id);
    }

    if (this.existingData.activeInd !== null) {
      this.dataForm.get('activeInd').setValue(this.existingData.activeInd);
    }
  }

  setModalHeader() {
    if (this.addEditMode === 'A') {
      this.modalHeader = "Adding a new Account"
    }
    else {
      this.modalHeader = "Edit Account - " + this.existingData.name;
    }
  }

  // Initialize the form with formbuilder and initialize validation fields 
  initializeForm() {
    this.dataForm = this.formBuilder.group({
      name: ['', [Validators.required,Validators.maxLength(200)]],
      accountNumber: ['', [Validators.required, Validators.maxLength(50)]],
      accountTypeId: ['', [Validators.required]],
      beginningBalance: [0, [Validators.required]],
      description: ['', [Validators.maxLength(200)]],
      comments: ['', [Validators.maxLength(2000)]],
      organizationId: [this.dataService.getLoginUserDetails().organizationId],
      activeInd: ['', [Validators.required]],
      id: [0],
    });
  }

  getAccountTypes(){
    this.dataService.getAccountTypes()
      .subscribe((result) => {
        if (result) {
          this.accountTypes = result;
        }
      }, error => console.error(error));
  }


  // Convenience getter for easy access to form fields
  get f() { return this.dataForm.controls; }

  // Called on submit of the form
  onSubmit() {
    this.submitted = true;
    this.spinner.show();
    // stop here if form is invalid
    if (this.dataForm.invalid) {
      //alert('Form Invalid');
      return;
    }

    
    if (this.addEditMode === 'A') {
      this.dataService.saveAccount(this.dataForm.value)
        .subscribe(() => {
          this.spinner.hide();
          this.activeModal.close();
        }, (err) => console.error(err));
    }
    if (this.addEditMode === 'E'){
      this.dataService.updateAccount(this.dataForm.value)
        .subscribe(() => {
          this.spinner.hide();
          this.activeModal.close();
        }, (err) => console.error(err));
    }

    this.spinner.hide();
  }
}


@Component({
  selector: 'app-account-activity-edit',
  templateUrl: './account-activity.component.html'
})

export class AccountActivityComponent implements OnInit{
  // Declarations
   @Input() account: any;  
   private dateRange: any;
   beginDateTime = null;
   endDateTime = null;
   totalAmount = null;
 
 
   public myDateRangePickerOptions: IMyDrpOptions = {
     // other options...
     editableDateRangeField: false,
     dateFormat: 'mm/dd/yyyy',
     showClearBtn: false,
     showClearDateRangeBtn: false
   };
  constructor(public activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService,
    public config: NgbModalConfig,
    private dataService: DataService) {
    config.backdrop = 'static';
    config.keyboard = false;

    
  }

  ngOnInit() {
    this.setRange();
    this.getAccountActivity();
  }

  setRange() {
    let date = new Date();
    var nowDate = new Date();
    nowDate.setDate(nowDate.getDate() - 30);
    this.dateRange = {
      beginDate: { year: nowDate.getFullYear(), month: nowDate.getMonth() + 1, day: nowDate.getDate() },
      endDate: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
    };
    //this.getAccountActivity();
  }

  getAccountActivity() {
    this.spinner.show();
    this.dataService.getAccountActivity({
      dateRange: this.dateRange,
      organizationId: 2,
      accountId: this.account.id
    })
      .subscribe((result) => {
        console.log(result);
        //this.expenseList = result.ledger;
        this.beginDateTime = result.beginDateTime;
        this.endDateTime = result.endDateTime;
        this.totalAmount = result.totalAmount;
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        console.error(error)
      });
  }

  onDateRangeChanged(event: IMyDateRangeModel) {
    if (event) {
      this.dateRange.beginDate = event.beginDate;
      this.dateRange.endDate = event.endDate;
      this.getAccountActivity();
    }
  }
}



