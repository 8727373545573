import { Component, OnInit, PipeTransform } from '@angular/core';
import { Directive, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { DataService } from '../data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { IMyDpOptions } from 'mydatepicker';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
    selector: 'app-contributors',
    templateUrl: './contributors.component.html',
  })
  export class ContributorsComponent implements OnInit{
  
    contributorsList=[];

  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'mm/dd/yyyy'
  };

    // Constructor
  constructor(private dataService: DataService,
    private modalService: NgbModal) {
  }


    //OnInit is called after constructor
  ngOnInit() {
    this.getContributors();
  }

  AddEditContributors(addEditMode: any) {
    const modalRef = this.modalService.open(ContributorsAddEditComponent, { size: 'xl' });
    modalRef.componentInstance.addEditMode = addEditMode;
    modalRef.result.then((data) => {
      // on close
      this.getContributors();
    }, () => {
      // on dismiss
    });
  }

  EditContributor(selectedContributor: any){
    const modalRef = this.modalService.open(ContributorsAddEditComponent, { size: 'xl' });
    modalRef.componentInstance.addEditMode = 'E';
    modalRef.componentInstance.existingContributor= selectedContributor;
    modalRef.result.then(() => {
      // on close
      this.getContributors();
    }, () => {
      // on dismiss
    });
  }

  getContributors(){
    this.dataService.getContributors(2)
    .subscribe((result) => {
      this.contributorsList= result;
      if (result) {
        //console.log(result);
      }
    }, error => console.error(error));
  }

  }


  @Component({
    selector: 'app-contributors-view',
    template: '<div class="container"><app-contributors></app-contributors></div>',
  })
  export class ContributorsViewComponent {
  
  }


  @Component({
    selector: 'app-contributors-add-edit',
    templateUrl: './contributors-add-edit.component.html'
  })
  
  export class ContributorsAddEditComponent implements OnInit {
    // Declarations
  @Input() addEditMode: any;
  @Input() existingContributor: any;

  modalHeader: string;  
  dataForm: FormGroup;
  submitted = false;
  accountTypes: any[];
  selectedSubCategories = [];
  agencyClientDetails: any;
  //agencySeq= 292;
  clientSeq = 281308;
  transactionSeq = 1;
  departmentsList=[];
  ministriesList=[];
  contributorStatusList=[];
  routeParamAgency = null;

  constructor(public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    public config: NgbModalConfig,
    private dataService: DataService,
    private spinner: NgxSpinnerService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {
    this.setModalHeader();
    this.initializeForm();
    this.getDepartments();
    this.getContributorStatus();

    if(this.addEditMode =='E'){
      this.initializeInformation();  
    }
  }

    //setDate(): void {
    //  // Set today date using the patchValue function
    //  const date = new Date();
    //  this.dataForm.patchValue({
    //    dOB: {
    //      date: {
    //        year: date.getFullYear(),
    //        month: date.getMonth() + 1,
    //        day: date.getDate()
    //      }
    //    }
    //  });
    //}

  // Get active departments
  getContributorStatus() {
    this.dataService.getContributorStatus()
      .subscribe((result) => {
        if (result) {
          this.contributorStatusList = result;
        }
      }, error => console.error(error));
  }

  // Get active departments
  getDepartments() {
    this.dataService.getDepartments()
      .subscribe((result) => {
        if (result) {
          this.departmentsList = result;
        }
      }, error => console.error(error));
  }

  setModalHeader() {
    if (this.addEditMode === 'A') {
      this.modalHeader = "Add Contributor"
    }
    if(this.addEditMode ==='E'){
      this.modalHeader = "Edit Contributor"    
    }
  }

  // Initialize the form with formbuilder and initialize validation fields 
  initializeForm() {
    this.dataForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.maxLength(50)]],
      lastName: ['', [Validators.required, Validators.maxLength(50)]],
      middleName: [null, [Validators.maxLength(50)]],
      addressLine1: [null, [Validators.maxLength(50)]],
      addressLine2: [null, [Validators.maxLength(50)]],
      city: [null, [Validators.maxLength(50)]],
      zip: [null, [Validators.maxLength(10)]],
      phone: [null, [Validators.maxLength(10)]],
      state: [null, [Validators.maxLength(50)]],
      departmentId: [''],
      ministryId: [''],
      email: ['', [Validators.required, Validators.email]],
      comments: [''],
      organizationId: [2],
      status: ['', [Validators.required]],
      id: [0],
      dOB: [null]
    });
  }

  initializeInformation(){
    this.dataForm.get('firstName').setValue(this.existingContributor.firstName);
    this.dataForm.get('lastName').setValue(this.existingContributor.lastName);
    this.dataForm.get('middleName').setValue(this.existingContributor.middleName);
    this.dataForm.get('addressLine1').setValue(this.existingContributor.addressLine1);
    this.dataForm.get('addressLine2').setValue(this.existingContributor.addressLine2);
    this.dataForm.get('city').setValue(this.existingContributor.city);
    this.dataForm.get('zip').setValue(this.existingContributor.zip);
    this.dataForm.get('phone').setValue(this.existingContributor.phone);
    this.dataForm.get('state').setValue(this.existingContributor.state);
    this.dataForm.get('departmentId').setValue(this.existingContributor.departmentId);
    this.dataForm.get('ministryId').setValue(this.existingContributor.ministryId);
    this.dataForm.get('email').setValue(this.existingContributor.email);
    this.dataForm.get('comments').setValue(this.existingContributor.comments);
    this.dataForm.get('organizationId').setValue(this.existingContributor.organizationId);
    this.dataForm.get('status').setValue(this.existingContributor.status);
    this.dataForm.get('id').setValue(this.existingContributor.id);
    //this.dataForm.get('dOB').setValue(this.existingContributor.dOB);
    this.setDate(this.existingContributor.dob);
  }

   setDate(retDOB): void {
      // Set today date using the patchValue function
     if (retDOB) {
       const date = new Date(retDOB);
       this.dataForm.patchValue({
         dOB: {
           date: {
             year: date.getFullYear(),
             month: date.getMonth() + 1,
             day: date.getDate()
           }
         }
       });
     }
     
    }

  // Convenience getter for easy access to form fields
  get f() { return this.dataForm.controls; }

  // Called on submit of the form
  onSubmit() {
    this.submitted = true;
    console.log(this.dataForm.value);

    // stop here if form is invalid
    if (this.dataForm.invalid) {
      //alert('Form Invalid');
      return;
    }
    this.spinner.show();

    if (this.addEditMode === 'A') {
      // Add new contributor
    this.dataService.saveContributor(this.dataForm.value)
    .subscribe((data) => {        
      console.log(data);
      this.activeModal.close();
      this.spinner.hide();
    }, (err) => console.error(err));  

    }
    if(this.addEditMode ==='E'){
      this.dataService.editContributor(this.dataForm.value)
    .subscribe((data) => {        
      console.log(data);
      this.activeModal.close();
      this.spinner.hide();
    }, (err) => console.error(err));     
    }

    
      // Edit Contributor
      this.spinner.hide();  
  }

  //$event: This value refers to the payload of the selected dropdown element.
  onSelectDepartment(e: { target: { value: number; }; }) {
    this.getMinistriesByDepartment(e.target.value);
  }

  getMinistriesByDepartment(departmentId: number){
    this.dataService.getMinistriesByDepartment(departmentId)
      .subscribe((result) => {
        if (result) {
          this.ministriesList = result;
        }
      }, error => console.error(error));
  }
    
  
  }
