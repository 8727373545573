import { Router, CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
    constructor(private dataService: DataService,
        public router: Router) {
      }

    canActivate(): boolean {  
        if(!this.dataService.isUserAuthenticated()){
            this.router.navigate(['']);
            return false;
        }      
        return true;
    }
  }