import { Component, OnInit, PipeTransform } from '@angular/core';
import { Directive, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import { IMyDpOptions } from 'mydatepicker';
import { IMyDrpOptions, IMyDateRangeModel } from 'mydaterangepicker';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label, Color } from 'ng2-charts';

interface Country {
  name: string;
  flag: string;
  area: number;
  population: number;
}

const COUNTRIES: Country[] = [
  {
    name: 'Russia',
    flag: 'f/f3/Flag_of_Russia.svg',
    area: 17075200,
    population: 146989754
  },
  {
    name: 'Canada',
    flag: 'c/cf/Flag_of_Canada.svg',
    area: 9976140,
    population: 36624199
  },
  {
    name: 'United States',
    flag: 'a/a4/Flag_of_the_United_States.svg',
    area: 9629091,
    population: 324459463
  },
  {
    name: 'China',
    flag: 'f/fa/Flag_of_the_People%27s_Republic_of_China.svg',
    area: 9596960,
    population: 1409517397
  }
];


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})

export class HomeComponent implements OnInit {
  countries = COUNTRIES;

  contributorsList = [];
  private dateRange: any;
  beginDateTime = null;
  endDateTime = null;
  totalIncomeAmount = null;
  totalExpenseAmount = null;
  showChart = false;


  public barChartLabels: Label[] = [''];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  public barChartColors: Color[] = [
    { backgroundColor: '#dc3545' },
    { backgroundColor: '#28a745' },
  ]


  public barChartData: ChartDataSets[] = [
    { data: [], label: 'Expense' },
    { data: [], label: 'Income' }
  ];

  public barChartOptions: any = { 
    legend: { display: true, labels: { fontColor: 'black' } },
  
    scales : {
      yAxes: [{
         ticks: {
            steps : 10,
            stepValue : 100,
            // max : 100,
            // min: 0
          }
      }]
    }
  
  };

  // Constructor
  constructor(private dataService: DataService,
    private spinner: NgxSpinnerService) {
  }

  //OnInit is called after constructor
  ngOnInit() {
    this.setRange();
    this.getExpense();
    this.getContributorBirthdays();

    //this.dataService.postMessage({ Subject: 'Test' }).subscribe((result) => {
    //  console.log(result);
    //}, error => {
    //  console.error(error)
    //});;
  }

  setRange() {
    let date = new Date();
    var nowDate = new Date();
    nowDate.setDate(nowDate.getDate() - 30);
    this.dateRange = {
      beginDate: { year: nowDate.getFullYear(), month: nowDate.getMonth() + 1, day: nowDate.getDate() },
      endDate: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
    };
  }

  getExpense() {
    this.spinner.show();
    this.dataService.getExpense({
      dateRange: this.dateRange,
      organizationId: 2
    })
      .subscribe((result) => {
        this.beginDateTime = result.beginDateTime;
        this.endDateTime = result.endDateTime;
        this.totalExpenseAmount = result.totalAmount;
        this.spinner.hide();
        this.getIncome();
      }, error => {
        this.spinner.hide();
        console.error(error)
      });
  }

  getIncome() {
    this.spinner.show();
    this.dataService.getIncome({
      dateRange: this.dateRange,
      organizationId: 2
    })
      .subscribe((result) => {
        this.beginDateTime = result.beginDateTime;
        this.endDateTime = result.endDateTime;
        this.totalIncomeAmount = result.totalAmount;

        const data0 = [this.totalExpenseAmount, "test1"];
        const data1 = [this.totalIncomeAmount, 'test2'];

        this.barChartData[0].data = data0;
        this.barChartData[1].data = data1;

        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        console.error(error)
      });
  }

  onDateRangeChanged(event: IMyDateRangeModel) {
    if (event) {
      this.dateRange.beginDate = event.beginDate;
      this.dateRange.endDate = event.endDate;
      console.log(this.dateRange);
      this.getIncome();
    }
  }

  getContributorBirthdays() {
    this.dataService.getContributorsBirthdays(2)
      .subscribe((result) => {
        this.contributorsList = result;
        if (result) {
          //console.log(result);
        }
      }, error => console.error(error));
  }

}
