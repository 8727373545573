import { Component, OnInit, PipeTransform } from '@angular/core';
import { Directive, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { DataService } from '../data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import {Router} from "@angular/router";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
  })
  export class LoginComponent {
    loginForm: FormGroup;
    submitted = false;
    errorLoggingIn= false;
  
    constructor(
      private formBuilder: FormBuilder,      
      private dataService: DataService,
      private spinner: NgxSpinnerService,
      private router: Router) {
    }
  
    ngOnInit() {
      this.dataService.deleteLoginUserDetails();
      this.initializeForm();
      this.initializeEmail();
    }  
    
  
    // Initialize the form with formbuilder and initialize validation fields 
    initializeForm() {
      this.loginForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.maxLength(50)]]
      });
    }
  
    initializeEmail(){
      if(this.dataService.checkUserEmailExists()){
        this.loginForm.get('email').setValue(this.dataService.getUserEmail());
      }
    }

    // Convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }
  
    // Called on submit of the form
    onSubmit() {      
      this.submitted = true;

      // stop here if form is invalid
      if (this.loginForm.invalid) {
        //alert('Form Invalid');
        return;
      }

      if(this.loginForm.valid){
        this.spinner.show();

        this.dataService.login(this.loginForm.value)
          .subscribe((data) => { 
            this.spinner.hide();
            if(data){
              console.log(data);
              console.log(this.dataService.setLoginUserDetails(data));
              this.router.navigate(['/home']);
            }
            else{
              this.errorLoggingIn= true;
            }  
          }, (err) => {
            this.spinner.hide();
            console.error(err)
          }); 
      }
         
    }
  }
  