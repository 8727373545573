import { Component, OnInit, PipeTransform } from '@angular/core';
import { Directive, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import { IMyDpOptions } from 'mydatepicker';
import { IMyDrpOptions, IMyDateRangeModel } from 'mydaterangepicker';


@Component({
  selector: 'app-income',
  templateUrl: './income.component.html',
})
export class IncomeComponent implements OnInit {

  incomeList = [];
  private dateRange: any;
  beginDateTime = null;
  endDateTime = null;
  totalAmount = null;
  departmentsList = [];
  ministriesList = [];
  contributorStatusList = [];
  contributorsList = [];
  accountsList = [];
  contributionTypesList = [];
  methodTypesList = [];

  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'mm/dd/yyyy'
  };

  public myDateRangePickerOptions: IMyDrpOptions = {
    // other options...
    editableDateRangeField: false,
    dateFormat: 'mm/dd/yyyy',
    showClearBtn: false,
    showClearDateRangeBtn: false
  };

  // Constructor
  constructor(private dataService: DataService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal) {
  }


  //OnInit is called after constructor
  ngOnInit() {
    this.setRange();
    this.getIncome();
    this.getContributors();
    this.getAccounts();
    this.getContributionTypes();
    this.getMethodGivingTypes();
  }

  setRange() {
    let date = new Date();
    var nowDate = new Date();
    nowDate.setDate(nowDate.getDate() - 30);
    this.dateRange = {
      beginDate: { year: nowDate.getFullYear(), month: nowDate.getMonth() + 1, day: nowDate.getDate() },
      endDate: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
    };
  }

  getContributors() {
    this.dataService.getContributors(2)
      .subscribe((result) => {
        this.contributorsList = result;
      }, error => console.error(error));
  }

  getContributorName(id) {
    const contributor = this.contributorsList.filter(x => x.id === id);
    return contributor[0].firstName + ' ' + contributor[0].lastName;
  }

  getAccounts() {
    this.dataService.getAccounts(2)
      .subscribe((result) => {
        if (result) {
          this.accountsList = result;
        }
      }, error => console.error(error));
  }

  getAccountName(id) {
    const account = this.accountsList.filter(x => x.id === id);
    return account[0].name;
  }

  getContributionTypes() {
    this.dataService.getContributionTypes()
      .subscribe((result) => {
        this.contributionTypesList = result;
      }, error => console.error(error));
  }

  getContributionTypeName(id) {
    const list = this.contributionTypesList.filter(x => x.id === id);
    return list[0].name;
  }

  //gMethodGivingTypes
  getMethodGivingTypes() {
    this.dataService.getMethodGivingTypes()
      .subscribe((result) => {
        this.methodTypesList = result;
      }, error => console.error(error));
  }

  getMethodGivingTypeName(id) {
    const list = this.methodTypesList.filter(x => x.id === id);
    return list[0].name;
  }

  getDepartments() {
    this.dataService.getDepartments()
      .subscribe((result) => {
        if (result) {
          this.departmentsList = result;
        }
      }, error => console.error(error));
  }
  AddEditContributors(addEditMode: any) {
    const modalRef = this.modalService.open(IncomeAddEditComponent, { size: 'xl' });
    modalRef.componentInstance.addEditMode = addEditMode;
    modalRef.result.then((data) => {
      // on close
      this.getIncome();
    }, (reason) => {
      // on dismiss
    });
  }

  EditData(selectedData: any) {
    const modalRef = this.modalService.open(IncomeAddEditComponent, { size: 'xl' });
    modalRef.componentInstance.addEditMode = 'E';
    modalRef.componentInstance.existingData = selectedData;
    modalRef.result.then((data) => {
      // on close
      this.getIncome();
    }, (reason) => {
      // on dismiss
    });
  }

  getIncome() {
    this.spinner.show();
    console.log(this.dateRange);
    this.dataService.getIncome({
      dateRange: this.dateRange,
      organizationId: 2
    })
      .subscribe((result) => {
        console.log(result);
        this.incomeList = result.ledgers;
        this.beginDateTime = result.beginDateTime;
        this.endDateTime = result.endDateTime;
        this.totalAmount = result.totalAmount;
        this.spinner.hide();
      }, error => {
          this.spinner.hide();
        console.error(error)
      });
  }

  onDateRangeChanged(event: IMyDateRangeModel) {
    if (event) {
      this.dateRange.beginDate = event.beginDate;
      this.dateRange.endDate = event.endDate;
      console.log(this.dateRange);
      this.getIncome();
    }
  }

}


@Component({
  selector: 'app-income-view',
  template: '<div class="container"><app-income></app-income></div>',
})
export class IncomeViewComponent {

}


@Component({
  selector: 'app-income-add-edit',
  templateUrl: './income-add-edit.component.html'
})

export class IncomeAddEditComponent implements OnInit {
  // Declarations
  @Input() addEditMode: any;
  @Input() existingData: any;

  modalHeader: string;
  dataForm: FormGroup;
  submitted = false;
  departmentsList = [];
  ministriesList = [];
  contributorStatusList = [];
  contributorsList = [];
  accountsList = [];
  contributionTypesList = [];
  methodTypesList = [];

  constructor(public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    public config: NgbModalConfig,
    private dataService: DataService,
    private spinner: NgxSpinnerService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {
    this.setModalHeader();
    this.initializeForm();
    this.getContributors();
    this.getDepartments();
    this.getAccounts();
    this.getContributionTypes();
    this.getMethodGivingTypes();

    if (this.addEditMode == 'E') {
      this.initializeInformation();
    }
  }

  setDate(): void {
    // Set today date using the patchValue function
    let date = new Date();
    this.dataForm.patchValue({
      dateEntered: {
        date: {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate()
        }
      }
    });
  }

  getContributors() {
    this.dataService.getContributors(2)
      .subscribe((result) => {
        this.contributorsList = result;
      }, error => console.error(error));
  }

  getAccounts() {
    this.dataService.getAccounts(2)
      .subscribe((result) => {
        if (result) {
          this.accountsList = result;
        }
      }, error => console.error(error));
  }

  getContributionTypes() {
    this.dataService.getContributionTypes()
      .subscribe((result) => {
        this.contributionTypesList = result;
      }, error => console.error(error));
  }

  //gMethodGivingTypes
  getMethodGivingTypes() {
    this.dataService.getMethodGivingTypes()
      .subscribe((result) => {
        this.methodTypesList = result;
      }, error => console.error(error));
  }

  getDepartments() {
    this.dataService.getDepartments()
      .subscribe((result) => {
        if (result) {
          this.departmentsList = result;
        }
      }, error => console.error(error));
  }

  setModalHeader() {
    if (this.addEditMode == 'A') {
      this.modalHeader = "Add Income"
    }
    if (this.addEditMode == 'E') {
      this.modalHeader = "Edit Income"
    }
  }

  // Initialize the form with formbuilder and initialize validation fields 
  initializeForm() {
    this.dataForm = this.formBuilder.group({
      dateEntered: [null, [Validators.required]],
      contributorId: ['', [Validators.required]],
      accountId: ['', [Validators.required]],
      contributionTypeId: ['', [Validators.required]],
      departmentId: [''],
      ministryId: [''],
      comments: [''],
      organizationId: [2],
      id: [0],
      amount: [0.00, [Validators.required]],
      userIdEnteredBy: [0],
      methodGivingTypeId: ['']
    });

    this.setDate();
    this.dataForm.get('userIdEnteredBy').setValue(this.dataService.getLoginUserId());
  }

  initializeInformation() {
    if (this.existingData.dateEntered !== null) {
      this.setDateEdit(this.existingData.dateEntered);
    }

    if (this.existingData.contributorId !== null) {
      this.dataForm.get('contributorId').setValue(this.existingData.contributorId);
    }

    if (this.existingData.accountId !== null) {
      this.dataForm.get('accountId').setValue(this.existingData.accountId);
    }

    if (this.existingData.contributionTypeId !== null) {
      this.dataForm.get('contributionTypeId').setValue(this.existingData.contributionTypeId);
    }

    if (this.existingData.departmentId !== null) {
      this.dataForm.get('departmentId').setValue(this.existingData.departmentId);
    }

    if (this.existingData.ministryId !== null) {
      this.dataForm.get('ministryId').setValue(this.existingData.ministryId);
    }

    if (this.existingData.id !== null) {
      this.dataForm.get('id').setValue(this.existingData.id);
    }

    if (this.existingData.comments !== null) {
      this.dataForm.get('comments').setValue(this.existingData.comments);
    }

    if (this.existingData.organizationId !== null) {
      this.dataForm.get('organizationId').setValue(this.existingData.organizationId);
    }

    if (this.existingData.amount !== null) {
      this.dataForm.get('amount').setValue(this.existingData.amount);
    }

    if (this.existingData.methodGivingTypeId !== null) {
      this.dataForm.get('methodGivingTypeId').setValue(this.existingData.methodGivingTypeId);
    }
  }

  setDateEdit(retDateEntered): void {
    if (retDateEntered) {
      const date = new Date(retDateEntered);
      this.dataForm.patchValue({
        dateEntered: {
          date: {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate()
          }
        }
      });
    }
  }

  // Convenience getter for easy access to form fields
  get f() { return this.dataForm.controls; }

  // Called on submit of the form
  onSubmit() {
    this.submitted = true;
    console.log(this.dataForm.value);
    console.log(this.dataForm);
    // stop here if form is invalid
    if (this.dataForm.invalid) {
      //alert('Form Invalid');
      return;
    }
    this.spinner.show();

    if (this.addEditMode == 'A') {
      // Add new Income
      this.dataService.saveIncome(this.dataForm.value)
        .subscribe((data) => {
          this.spinner.hide();
          this.activeModal.close();
        }, (err) => console.error("Failed! " + err));

    }
    if (this.addEditMode == 'E') {
      // Edit Income
      this.dataService.editIncome(this.dataForm.value)
        .subscribe((data) => {
          console.log(data)
          this.spinner.hide();
          this.activeModal.close();
        }, (err) => console.error("Failed! " + err));
    }


    // Edit Contributor
    this.spinner.hide();
  }

  //$event: This value refers to the payload of the selected dropdown element.
  onSelectDepartment(e: { target: { value: number; }; }) {
    this.getMinistriesByDepartment(e.target.value);
  }

  getMinistriesByDepartment(departmentId: number) {
    this.dataService.getMinistriesByDepartment(departmentId)
      .subscribe((result) => {
        if (result) {
          this.ministriesList = result;
        }
      }, error => console.error(error));
  }
}

