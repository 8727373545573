import { Component, ViewChild, ElementRef,OnInit, PipeTransform } from '@angular/core';
import { Directive, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import { IMyDpOptions } from 'mydatepicker';
import { IMyDrpOptions, IMyDateRangeModel } from 'mydaterangepicker';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
})

export class ReportsComponent implements OnInit {
 
  contributorsList = [];
  //private dateRange: any;
  dataForm: FormGroup;
  submitted = false;
  reportResultsList = [];
  showReportResult = false;
  printDate = new Date();
  departmentsList = [];
  ministriesList = [];
  contributionTypesList = [];
  methodTypesList = [];

  public myDateRangePickerOptions: IMyDrpOptions = {
    // other options...
    editableDateRangeField: false,
    dateFormat: 'mm/dd/yyyy',
    showClearBtn: false,
    showClearDateRangeBtn: false
  };
  // Constructor
  constructor(private dataService: DataService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal) {
  }

  //OnInit is called after constructor
  ngOnInit() {
    this.initializeForm();
    this.getContributors();
    this.getDepartments();
    this.getMinistries();
    this.getContributionTypes();
    this.getMethodGivingTypes();
    //this.setRange();
  }

  getContributionTypes() {
    this.dataService.getContributionTypes()
      .subscribe((result) => {
        this.contributionTypesList = result;
      }, error => console.error(error));
  }

  getContributionTypeName(id) {
    const list = this.contributionTypesList.filter(x => x.id === id);
    return list[0].name;
  }

  getDepartments() {
    this.dataService.getDepartments()
      .subscribe((result) => {
        if (result) {
          this.departmentsList = result;
        }
      }, error => console.error(error));
  }

  getDepartmentName(id) {
    const list = this.departmentsList.filter(x => x.id === id);
    return list[0].name;
  }

  getMinistries() {
    this.dataService.getMinistries()
      .subscribe((result) => {
        if (result) {
          this.ministriesList = result;
        }
      }, error => console.error(error));
  }

  getMinistryName(id) {
    const list = this.ministriesList.filter(x => x.id === id);
    return list[0].name;
  }

  //gMethodGivingTypes
  getMethodGivingTypes() {
    this.dataService.getMethodGivingTypes()
      .subscribe((result) => {
        this.methodTypesList = result;
      }, error => console.error(error));
  }

  getMethodGivingTypeName(id) {
    const list = this.methodTypesList.filter(x => x.id === id);
    return list[0].name;
  }
  //onDateRangeChanged(event: IMyDateRangeModel) {
  //  if (event) {
  //    this.dateRange.beginDate = event.beginDate;
  //    this.dateRange.endDate = event.endDate;
  //    this.getIncome();
  //  }
  //}

  // Initialize the form with formbuilder and initialize validation fields 
  initializeForm() {
    this.dataForm = this.formBuilder.group({
      dateRange: [null, [Validators.required]],
      contributorId: ['', [Validators.required]],
      organizationId: [2]
      //accountId: ['', [Validators.required]],
      //contributionTypeId: ['', [Validators.required]],
      //departmentId: ['', [Validators.required]],
      //ministryId: [''],
      //comments: [''],      
      ////id: [0],
      //amount: [0.00, [Validators.required]],
      //userIdEnteredBy: [0]
    });

    this.setDate();
    //this.dataForm.get('userIdEnteredBy').setValue(this.dataService.getLoginUserId());
  }

  // Convenience getter for easy access to form fields
  get f() { return this.dataForm.controls; }

  // Called on submit of the form
  onSubmit() {
    this.submitted = true;
    //console.log(this.dataForm.value);
    //console.log(this.dataForm);
    // stop here if form is invalid
    if (this.dataForm.invalid) {
      //alert('Form Invalid');
      return;
    }
    this.spinner.show();

    this.dataService.getContributorSummary(this.dataForm.value)
      .subscribe((result) => {
        console.log(result);
        this.reportResultsList = result;
        this.showReportResult = true;
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        console.error(error)
      });


    // Edit Contributor
    this.spinner.hide();
  }

  setDate(): void {
    // Set today date using the patchValue function
    const date = new Date();
    const nowDate = new Date();
    nowDate.setDate(nowDate.getDate() - 30);

    this.dataForm.patchValue({
      dateRange: {
        beginDate: { year: nowDate.getFullYear(), month: nowDate.getMonth() + 1, day: nowDate.getDate() },
        endDate: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
      }
    });
  }

  getContributors() {
    this.dataService.getContributors(2)
      .subscribe((result) => {
        this.contributorsList = result;
      }, error => console.error(error));
  }

  downloadPDF() {

    var node = document.getElementById('printDiv2');

    var img;
    var filename;
    var newImage;


    domtoimage.toPng(node, { bgcolor: '#fff' })

      .then(function (dataUrl) {

        img = new Image();
        img.src = dataUrl;
        newImage = img.src;

        img.onload = function () {

          var pdfWidth = img.width;
          var pdfHeight = img.height;

          console.log(pdfWidth);
          console.log(pdfHeight);
          // FileSaver.saveAs(dataUrl, 'my-pdfimage.png'); // Save as Image

          var doc;

          if (pdfWidth > pdfHeight) {
            doc = new jsPDF('l', 'px', [pdfWidth, pdfHeight]);
          }
          else {
            doc = new jsPDF('p', 'px', [pdfWidth, pdfHeight]);
          }
          //if (pdfWidth > pdfHeight) {
          //  doc = new jsPDF('l', 'px', [800, 300]);
          //}
          //else {
          //  doc = new jsPDF('p', 'px', [200, 300]);
          //}

          var width = doc.internal.pageSize.getWidth();
          var height = doc.internal.pageSize.getHeight();

          console.log('downloadPDF');
          console.log(width);
          console.log(height);

          doc.addImage(newImage, 'PNG', 10, 10, width, height);
          filename = 'Word_of_Faith_Contributor_Summary.pdf';
          doc.save(filename);

        };


      })
      .catch(function (error) {

        // Error Handling

      });
  }

  htmltoPDF() {
    // parentdiv is the html element which has to be converted to PDF
    html2canvas(document.querySelector("#printDiv2")).then(canvas => {

      var pdf = new jsPDF('p', 'pt', [canvas.width, canvas.height]);

      console.log('htmltoPDF');
      console.log(canvas.width);
      console.log(canvas.height);

      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      pdf.addImage(imgData, 0, 0, canvas.width, canvas.height);
      pdf.save('converteddoc.pdf');
      //pdf.autoPrint();
    });
  }

  captureScreen() {
    var data = document.getElementById('printDiv2');
    html2canvas(data).then(canvas => {
      // Few necessary setting options  
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      //let pdf = new jsPDF({
      //  orientation: "portrait",
      //  unit: "in",
      //  format: [8.5,11]
      //});
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      console.log('capture screen');
      console.log(imgWidth);
      console.log(imgHeight);
      pdf.save('MYPdf.pdf'); // Generated PDF
      //pdf.autoPrint();
    });
  }

  //captureScreen() {
  //  var data = document.getElementById('printDiv2');
  //    var doc = new jsPDF();
  //  console.log(data);
  //    doc.html(data.innerHTML, {
  //      callback: function (doc) {
  //        doc.save();
  //      },
  //      x: 10,
  //      y: 10,
  //      html2canvas: s
  //    });

  //    //pdf.save('MYPdf.pdf'); // Generated PDF   
  //}

  downloadPDF2() {
    //const printContent = document.getElementById("printDiv2");
    //const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
    //WindowPrt.document.write(printContent.innerHTML);
    ////WindowPrt.document.close();
    //WindowPrt.focus();
    //WindowPrt.print();
    //WindowPrt.close();
    //window.print();

    const beforePrint = function () {
      console.log('Functionality to run before printing.');
    };

    const afterPrint = function () {
      window.location.reload();
    };

    if (window.matchMedia) {
      var mediaQueryList = window.matchMedia('print');
      mediaQueryList.addListener(function (mql) {
        if (mql.matches) {
          beforePrint();
        } else {
          afterPrint();
        }
      });
    }

    window.onbeforeprint = beforePrint;
    window.onafterprint = afterPrint;

    //Working Code starts
    let printContents = document.getElementById("printDiv2").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    // Working code ends

    

    //const modalRef = this.modalService.open(ReportViewerComponent, { size: 'xl' });
    //modalRef.componentInstance.reportContent = printContents;
    //modalRef.result.then((data) => {
    //  // on close
    //  //this.getContributors();
    //}, () => {
    //  // on dismiss
    //});
  }

  resetForm() {
    this.ngOnInit();
  }
}



@Component({
  selector: 'app-reports-viewer',
  templateUrl: './report-viewer-component.html',
})


export class ReportViewerComponent implements OnInit {
  @Input() reportContent: any;

  constructor(public activeModal: NgbActiveModal,
    public config: NgbModalConfig,) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {
    //window.print();
  }

  print() {
    let printContents = document.getElementById("reportViewerDiv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    // Working code ends
  }
}
