import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})

@Inject({
  baseUrl: 'BASE_URL'
})
export class DataService {

  private baseUrl: string = undefined;

  constructor(private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private cookieService: CookieService) {
    this.baseUrl = baseUrl
  }

  //#region "Local Storage methods"

  deleteAllCookies() {
    this.cookieService.deleteAll();
  }

  setUserEmail(email: string) {
    this.cookieService.set('Email', email);
    return this.cookieService.check('Email');
  }

  getUserEmail() {
    return this.cookieService.get('Email');
  }

  checkUserEmailExists() {
    return this.cookieService.check('Email');
  }

  setLoginUserDetails(data: object) {
    this.cookieService.set('UserDetails', JSON.stringify(data));
    return JSON.parse(this.cookieService.get('UserDetails'));
  }

  getLoginUserDetails() {
    if(this.cookieService.get('UserDetails')){
      return JSON.parse(this.cookieService.get('UserDetails'));
    }
    else{
      return null;
    }
    
  }

  getLoginUserId() {
    if(this.cookieService.get('UserDetails')){
      const userDataJson= JSON.parse(this.cookieService.get('UserDetails'));
      return userDataJson.id;
    }
    else{
      return 13;
    }
    //var userDetails= this.getLoginUserDetails();
    //return 12;//userDetails.id;    
  }

  deleteLoginUserDetails() {
    this.cookieService.delete('UserDetails');
  }

  isUserAuthenticated() {
    const userDetails = this.getLoginUserDetails();
    if (userDetails && userDetails.id) {
      return true;
    }
    else {
      return false;
    }
  }

  getUserName(){
    if(this.cookieService.get('UserDetails')){
      var userDataJson= JSON.parse(this.cookieService.get('UserDetails'));
      return userDataJson.firstName + ' ' + userDataJson.lastName;
    }
    else{
      return null;
    }
  }
  //#endregion

  // Get accounts of an Org
  getAccounts(orgSeq: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'api/Data/GetAccounts/' + orgSeq);
  }

  // Get accounts of an Org
  getAccountTypes(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'api/Data/GetAccountTypes');
  }

  // Get accounts of an Org
  getOrganizations(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'api/Data/GetOrganizations');
  }

  // Save Registration
  saveRegistration(data: object): Observable<any> {
    return this.http.post(this.baseUrl + 'api/Data/saveRegistration', data, { responseType: 'text' });
  }

  // Check User
  checkUser(data: object): Observable<any> {
    return this.http.post(this.baseUrl + 'api/Data/CheckEmail', data);
  }

  // Login User
  login(data: object): Observable<any> {
    return this.http.post(this.baseUrl + 'api/Data/Login', data);
  }

  // Get registered users
  getRegisteredUsers(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'api/Data/GetRegisteredUsers');
  }

  // Approve User
  approveDenyUser(data: object): Observable<any> {
    return this.http.post(this.baseUrl + 'api/Data/ApproveDenyUser', data, { responseType: 'text' });
  }

  // Make user admin
  userAdmin(data: object): Observable<any> {
    return this.http.post(this.baseUrl + 'api/Data/UserAdmin', data, { responseType: 'text' });
  }

  // Get departments
  getDepartments(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'api/Data/GetDepartments');
  }

  // Get departments
  getMinistries(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'api/Data/GetMinistries');
  }

  // Get departments
  getMinistriesByDepartment(departmentId: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'api/Data/GetMinistriesByDepartment/' + departmentId);
  }

  // Get Contributors
  getContributorStatus(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'api/Data/GetContributorStatus');
  }

  // Get Contributors
  getContributors(orgSeq: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'api/Data/GetContributors/' + orgSeq);
  }

  // Get Contributor Birthdays
  getContributorsBirthdays(orgSeq: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'api/Data/GetContributorsBirthdays/' + orgSeq);
  }

  // Save contributor
  saveContributor(data: object): Observable<any> {
    console.log(data);
    return this.http.post(this.baseUrl + 'api/Data/SaveContributor', data);
  }

  // Edit contributor
  editContributor(data: object): Observable<any> {
    return this.http.post(this.baseUrl + 'api/Data/EditContributor', data);
  }

  // Get Contribution types
  getContributionTypes(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'api/Data/GetContributionTypes');
  }

  // Get MethodGivingTypes types
  getMethodGivingTypes(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'api/Data/GetMethodGivingTypes');
  }

  // Save Income
  saveIncome(data: object): Observable<any> {
    return this.http.post(this.baseUrl + 'api/Data/SaveIncome', data);
  }

  // Edit Income
  editIncome(data: object): Observable<any> {
    return this.http.post(this.baseUrl + 'api/Data/EditIncome', data);
  }

  // Get Contributors
  getIncome(data: object): Observable<any> {
    return this.http.post(this.baseUrl + 'api/Data/GetIncome', data);
  }

  // Save Expense
  saveExpense(data: object): Observable<any> {
    return this.http.post(this.baseUrl + 'api/Data/SaveExpense', data);
  }

  // Get Expenses
  getExpense(data: object): Observable<any> {
    return this.http.post(this.baseUrl + 'api/Data/GetExpense', data);
  }

  // Get Bill pay types
  getBillPayTypes(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'api/Data/GetBillPayTypes');
  }

  // Save BillPayTypes
  saveBillPayTypes(data: object): Observable<any> {
    return this.http.post(this.baseUrl + 'api/Data/SaveBillPayTypes', data);
  }

  // Edit BillPayTypes
  editBillPayTypes(data: object): Observable<any> {
    return this.http.post(this.baseUrl + 'api/Data/EditBillPayTypes', data);
  }

  // Get Account Activity
  getAccountActivity(data: object): Observable<any> {
    return this.http.post(this.baseUrl + 'api/Data/GetAccountActivity', data);
  }

  // Save Account
  saveAccount(data: object): Observable<any> {
    return this.http.post(this.baseUrl + 'api/Data/SaveAccount', data);
  }

  // Update Account
  updateAccount(data: object): Observable<any> {
    return this.http.post(this.baseUrl + 'api/Data/UpdateAccount', data);
  }

  // Sending Emails
  postMessage(data: object): Observable<any> {
    return this.http.post(this.baseUrl + 'api/Data/PostMessage', data);
  }

  //GetContributorSummary
  getContributorSummary(data: object): Observable<any> {
    return this.http.post(this.baseUrl + 'api/Data/GetContributorSummary', data);
  }
}

