import { Component, OnInit, PipeTransform } from '@angular/core';
import {Router} from "@angular/router";
import { Directive, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { DataService } from '../data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
})
export class RegisterComponent {
  accountForm: FormGroup;
  submitted = false;
  emailExists= false;
  organizationsList = [];

  constructor(
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private router: Router) {
  }

  ngOnInit() {
    this.getOrganizations();
    this.initializeForm();
  }

  // Get active Organizations from database
  getOrganizations() {
    this.dataService.getOrganizations()
      .subscribe((result) => {
        if (result) {
          this.organizationsList = result;
        }
      }, error => console.error(error));
  }

  // Initialize the form with formbuilder and initialize validation fields 
  initializeForm() {
    this.accountForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.maxLength(50)]],
      organizationId: ['', [Validators.required]],
      securityQuestion1: ['', [Validators.required, Validators.maxLength(200)]],
      securityAnswer1: ['', [Validators.required, Validators.maxLength(200)]],
      securityQuestion2: ['', [Validators.required, Validators.maxLength(200)]],
      securityAnswer2: ['', [Validators.required, Validators.maxLength(200)]],
      // more details
      firstName: ['', [Validators.required, Validators.maxLength(50)]],
      lastName: ['', [Validators.required, Validators.maxLength(50)]],
      middleName: [null, [Validators.maxLength(50)]],
      addressLine1: [null, [Validators.maxLength(50)]],
      addressLine2: [null, [Validators.maxLength(50)]],
      city: [null, [Validators.maxLength(50)]],
      zip: [null, [Validators.maxLength(10)]],
      phone: [null, [Validators.maxLength(10)]],
      state: [null, [Validators.maxLength(50)]]
    });
  }


  // Convenience getter for easy access to form fields
  get f() { return this.accountForm.controls; }

  // Function called when Org is selected from the list
  changeOrganization(event: any) {
    this.accountForm.get('organizationId').setValue(event.target.value, {
      onlySelf: true
    })
  }

  checkEmail(event: any) {    
    var intv= this.checkUser(event.target.value);
  }

  checkUser(emailAddr: string){
    this.dataService.checkUser({Email: emailAddr})
      .subscribe((data) => {
        if(data > 0){
          this.emailExists= true;
        }
        else{
          this.emailExists= false;
        }
          return data;
      }, (err) => console.error(err));
  }

  // Called on submit of the form
  onSubmit() {
    this.submitted = true;
    console.log(this.accountForm.value);

    // stop here if form is invalid
    if (this.accountForm.invalid) {
      return;
    }

    // If email exists, return
    if (this.emailExists) {
      return;
    }

    this.dataService.saveRegistration(this.accountForm.value)
      .subscribe((data) => {        
        if(data){
          if(this.dataService.setUserEmail(data)){
            // redirect to login page
            this.router.navigate(['/']);
          }
        }
      }, (err) => console.error(err));    
  }

  testMethod(){
    this.router.navigate(['/']);
  }
}

@Component({
  selector: 'app-registered-users-dashboard',
  template: '<div><app-registered-users [mode]="\'D\'"></app-registered-users></div>',
})
export class RegisteredUsersDashboardComponent {
}

@Component({
  selector: 'app-registered-users-view',
  template: '<div class="container"><app-registered-users></app-registered-users></div>',
})
export class RegisteredUsersViewComponent {

}

@Component({
  selector: 'app-registered-users',
  templateUrl: './registered-users.component.html',
})
export class RegisteredUsersComponent implements OnInit{
  @Input() mode: string;
  registeredUsersList=[];

  // Constructor
  constructor(private dataService: DataService,
    private spinner: NgxSpinnerService) {
  }

  //OnInit is called after constructor
  ngOnInit() {
    this.getRegisteredUsers();
  }

  getRegisteredUsers(){
    this.spinner.show();
    this.dataService.getRegisteredUsers()
    .subscribe((result) => {
      if(result){
        this.registeredUsersList = result;
      } 
      this.spinner.hide();     
    }, (err) => {
      this.spinner.hide();
      console.error(err)
    });
  }

  approveDenyUser(id: any, activeInd: string){    
    this.spinner.show();
    this.dataService.approveDenyUser({
      Id: id,
      ActiveInd: activeInd
    })
    .subscribe((result) => {
      if(result>0){
        this.getRegisteredUsers();
      }
      this.spinner.hide();       
    }, (err) => {
      this.spinner.hide();
      console.error(err)
    });
  }

  userAdmin(id: any, adminInd: string){    
    this.spinner.show();
    this.dataService.userAdmin({
      Id: id,
      Adminind: adminInd
    })
    .subscribe((result) => {
      if(result>0){
        this.getRegisteredUsers();
      }
      this.spinner.hide();       
    }, (err) => {
      this.spinner.hide();
      console.error(err)
    });
  }

}




