import { Component, OnInit, PipeTransform } from '@angular/core';
import { Directive, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-ledgeradmin',
  templateUrl: './ledgeradmin.component.html',
})
export class LedgerAdminComponent implements OnInit {

  expenseList = [];
  private dateRange: any;
  beginDateTime = null;
  endDateTime = null;
  totalAmount = null;
  billPayTypesList = [];

  // Constructor
  constructor(private dataService: DataService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal) {
  }

  //OnInit is called after constructor
  ngOnInit() {
    this.getBillPayTypes();
  }

  getBillPayTypes() {
    this.spinner.show();
    this.dataService.getBillPayTypes()
      .subscribe((result) => {
        this.spinner.hide();
        this.billPayTypesList = result;
      }, error => {
        this.spinner.hide();
      });
  }

  AddEditContributors(addEditMode: any) {
    const modalRef = this.modalService.open(LedgerAdminAddEditComponent, { size: 'xl' });
    modalRef.componentInstance.addEditMode = addEditMode;
    modalRef.result.then((data) => {
      // on close
      this.getBillPayTypes();
    }, (reason) => {
      // on dismiss
    });
  }

  EditData(selectedData: any) {
    const modalRef = this.modalService.open(LedgerAdminAddEditComponent, { size: 'xl' });
    modalRef.componentInstance.addEditMode = 'E';
    modalRef.componentInstance.existingData = selectedData;
    modalRef.result.then((data) => {
      // on close
      this.getBillPayTypes();
    }, (reason) => {
      // on dismiss
    });
  }

}


@Component({
  selector: 'app-ledgeradmin-view',
  template: '<div class="container"><app-ledgeradmin></app-ledgeradmin></div>',
})
export class LedgerAdminViewComponent {

}


@Component({
  selector: 'app-ledgeradmin-add-edit',
  templateUrl: './ledgeradmin-add-edit.component.html'
})

export class LedgerAdminAddEditComponent implements OnInit {
  // Declarations
  @Input() addEditMode: any;
  @Input() existingData: any;

  modalHeader: string;
  dataForm: FormGroup;
  submitted = false;

  constructor(public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    public config: NgbModalConfig,
    private dataService: DataService,
    private spinner: NgxSpinnerService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {
    this.setModalHeader();
    this.initializeForm();

    if (this.addEditMode == 'E') {
      this.initializeInformation();
    }
  }

  setModalHeader() {
    if (this.addEditMode == 'A') {
      this.modalHeader = "Add Expense Type"
    }
    if (this.addEditMode == 'E') {
      this.modalHeader = "Edit Expense Type"
    }
  }

  // Initialize the form with formbuilder and initialize validation fields 
  initializeForm() {
    this.dataForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      comments: [''],
      id: [0],
      userIdEnteredBy: [0],
      activeInd: ['', [Validators.required]],
    });

    this.dataForm.get('userIdEnteredBy').setValue(this.dataService.getLoginUserId());
  }

  initializeInformation() {
    if (this.existingData.name !== null) {
      this.dataForm.get('name').setValue(this.existingData.name);
    }

    if (this.existingData.id !== null) {
      this.dataForm.get('id').setValue(this.existingData.id);
    }

    if (this.existingData.comments !== null) {
      this.dataForm.get('comments').setValue(this.existingData.comments);
    }

    if (this.existingData.activeInd !== null) {
        this.dataForm.get('activeInd').setValue(this.existingData.activeInd);
      }
  }

  // Convenience getter for easy access to form fields
  get f() { return this.dataForm.controls; }

  // Called on submit of the form
  onSubmit() {
    this.submitted = true;
    console.log(this.dataForm.value);
    console.log(this.dataForm);
    // stop here if form is invalid
    if (this.dataForm.invalid) {
      //alert('Form Invalid');
      return;
    }
    this.spinner.show();

    if (this.addEditMode == 'A') {
      this.dataService.saveBillPayTypes(this.dataForm.value)
        .subscribe((data) => {
          this.spinner.hide();
          this.activeModal.close();
        }, (err) => console.error("Failed! " + err));

    }
    if (this.addEditMode == 'E') {
      this.dataService.editBillPayTypes(this.dataForm.value)
        .subscribe((data) => {
          console.log(data)
          this.spinner.hide();
          this.activeModal.close();
        }, (err) => console.error("Failed! " + err));
    }
    this.spinner.hide();
  }
}