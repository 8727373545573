import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule } from 'ng2-charts';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerModule } from "ngx-spinner";
import { MyDatePickerModule } from 'mydatepicker';
import { MyDateRangePickerModule } from 'mydaterangepicker';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { AccountsComponent, AccountsViewComponent, AccountsViewDashboardComponent, AccountAddEditComponent, AccountActivityComponent } from './accounts/accounts.component';
import {LoginComponent} from './login/login.component';
import {RegisterComponent, RegisteredUsersComponent, RegisteredUsersDashboardComponent, RegisteredUsersViewComponent} from './register/register.component';
import {ContributorsComponent, ContributorsViewComponent, ContributorsAddEditComponent} from './contributors/contributors.component';
import {IncomeComponent, IncomeViewComponent, IncomeAddEditComponent} from './income/income.component';
import { LedgerAdminComponent, LedgerAdminAddEditComponent} from './ledgeradmin/ledgeradmin.component';
import { ExpenseComponent, ExpenseViewComponent, ExpenseAddEditComponent } from './expense/expense.component';
import { ReportsComponent, ReportViewerComponent } from './reports/reports.component';
import {AuthGuardService as AuthGuard } from './auth-guard.service';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    AccountsComponent,
    AccountsViewComponent,
    AccountsViewDashboardComponent,
    AccountAddEditComponent,
    AccountActivityComponent,
    LoginComponent,
    RegisterComponent,
    RegisteredUsersComponent,
    RegisteredUsersDashboardComponent,
    RegisteredUsersViewComponent,
    ContributorsComponent,
    ContributorsViewComponent,
    ContributorsAddEditComponent,
    IncomeComponent,
    IncomeViewComponent,
    IncomeAddEditComponent,
    ExpenseComponent, 
    ExpenseViewComponent, 
    ExpenseAddEditComponent,
    ReportsComponent,
    ReportViewerComponent,
    LedgerAdminComponent,
    LedgerAdminAddEditComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot([
      { path: '', component: LoginComponent, pathMatch: 'full' },
      // { path: 'login/:email', component: LoginComponent },
      { path: 'home', component: HomeComponent, pathMatch: 'full',canActivate: [AuthGuard]},
      { path: 'counter', component: CounterComponent,canActivate: [AuthGuard]  },
      { path: 'fetch-data', component: FetchDataComponent, canActivate: [AuthGuard]  },
      { path: 'accounts', component: AccountsViewComponent, canActivate: [AuthGuard]  },
      { path: 'users', component: RegisteredUsersViewComponent,canActivate: [AuthGuard]  },
      { path: 'register', component: RegisterComponent},
      { path: 'contributors', component: ContributorsViewComponent, canActivate: [AuthGuard]  },
      { path: 'income', component: IncomeComponent, canActivate: [AuthGuard] },
      { path: 'maintenance', component: LedgerAdminComponent, canActivate: [AuthGuard] },
      { path: 'expense', component: ExpenseViewComponent, canActivate: [AuthGuard] },
      { path: 'reports', component: ReportsComponent, canActivate: [AuthGuard] }
    ]),
    NgbModule,
    ChartsModule,
    NgxSpinnerModule,
    MyDatePickerModule,
    MyDateRangePickerModule
    
  ],
  providers: [ CookieService ],
  bootstrap: [AppComponent],
  entryComponents: [AccountAddEditComponent, AccountActivityComponent,
    ContributorsAddEditComponent, 
    IncomeAddEditComponent,
    ExpenseAddEditComponent,
    LedgerAdminAddEditComponent,
    ReportViewerComponent]
})
export class AppModule { }
