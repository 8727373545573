import { Component } from '@angular/core';
import { DataService } from '../data.service';
import {Router} from "@angular/router";

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  isExpanded = false;

  // Constructor
  constructor(private dataService: DataService,
    private router: Router) {
  }
  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  checkAuthenticated(){
    return this.dataService.isUserAuthenticated();
  }

  getUserName(){
    return this.dataService.getUserName();
  }

  logoutUser(){
    this.dataService.deleteLoginUserDetails();
    this.router.navigate(['/']);
  }
}
